// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-03-09T17:17:49.200Z

interface IAWSAmplifyFederatedConfiguration {
  google_client_id?: string;
  facebook_app_id?: string;
  amazon_client_id?: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
  [index: number]: {
    endpoint: string;
    name: string;
    region: string;
  };
}

interface IAWSAmplifyConfiguration {
  aws_appsync_authenticationType?: string;
  aws_appsync_graphqlEndpoint?: string;
  aws_appsync_region?: string;
  aws_cognito_identity_pool_id?: string;
  aws_cognito_region?: string;
  aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
  aws_project_region: string;
  aws_user_files_s3_bucket?: string;
  aws_user_files_s3_bucket_region?: string;
  aws_user_pools_id?: string;
  aws_user_pools_web_client_id?: string;
  aws_user_pools_web_client_secret?: string;
  aws_mobile_analytics_app_id?: string;
  federated?: IAWSAmplifyFederatedConfiguration;
  aws_rest_api_url?: string;
}

const awsmobile: IAWSAmplifyConfiguration = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://appsync.app.getspacehub.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_cognito_identity_pool_id: 'eu-west-1:9eb7ae78-57fb-4a1b-8120-66ad689ac7a6',
  aws_cognito_region: 'eu-west-1',
  aws_project_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_KqLtqOAQe',
  aws_user_pools_web_client_id: '4joehgo8lbp8ufjdaumo6dv0nv',
  aws_mobile_analytics_app_id: 'a914f39bd29c4362935317aaef2ed1e4',
  aws_rest_api_url: 'https://rest.app.getspacehub.com',
};

export default awsmobile;
